import { useState } from "react";
import { toast } from "react-toastify";
import validator from "validator";

import { User } from "../../contexts/user";

import close from "../../assets/close.svg";
import copy from "../../assets/copy_icon.svg";
import send_mail from "../../assets/send_mail_icon.svg";

import styles from "./styles.module.scss";
import { baseUrl } from "../../config";

export default function Share() {
  const { setShareModal } = User();
  const [copied, setCopied] = useState(false);

  const [email, setEmail] = useState(null);

  const location = window.location;

  const sendLink = async () => {
    if (!email || !validator.isEmail(email)) {
      return toast.error("Email field is badly formatted or empty");
    }

    toast.info("Sending email");
    const token = sessionStorage.getItem("token");
    const path = `${location.origin}/document/${sessionStorage.getItem(
      "slugLink"
    )}`;
    const { volume, title, assented_by } = JSON.parse(
      sessionStorage.getItem("shareData")
    );

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        body: path,
        title,
        volume,
        assented_by,
      }),
    };

    await fetch(`${baseUrl}/share-document`, options);
    toast.success("Email Sent");
  };

  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>SHARE</h4>
          <img
            src={close}
            alt="Close Modal"
            onClick={() => setShareModal(false)}
          />
        </div>
        <div className={styles.share_modal_input}>
          <label htmlFor="">Please input your email</label>
          <div className={styles.share_modal_input_group}>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="name@example.com"
            />
            <div>
              <img onClick={sendLink} src={send_mail} alt="mail" />
            </div>
          </div>
        </div>

        <div className={styles.share_modal_input}>
          <label htmlFor="">Please share by copying the link below</label>
          <div className={styles.share_modal_input_group}>
            <input
              type="text"
              value={`${location.origin}/document/${sessionStorage.getItem(
                "slugLink"
              )}`}
              readOnly
            />
            <div>
              {copied ? (
                <span style={{ color: "#fff", fontSize: "11px" }}>copied</span>
              ) : (
                <img
                  onClick={() => {
                    setCopied(true);
                    navigator.clipboard.writeText(
                      `${location.origin}/document/${sessionStorage.getItem(
                        "slugLink"
                      )}`
                    );
                  }}
                  src={copy}
                  alt="mail"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
