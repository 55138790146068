import React, { useEffect, useState } from "react";

import components from "../../components";

import ReactGA from "react-ga";
import styles from "./styles.module.scss";

import nilds from "../../assets/nilds.svg";
import konrad from "../../assets/konrad.jpg";

import nilds_black from "../../assets/nilds_black.svg";
import konrad_black from "../../assets/konrad_black.jpg";

import about from "../../assets/gazette-pub.jpeg";

export default function About() {
  useEffect(() => {
    document.title = "About - Nigerian Gazettes";
    ReactGA.initialize("UA-215525316-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [nildsHover, setNildsHover] = useState(false);
  const [konradHover, setKonradHover] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        maxHeight: "fit-content",
        overflow: "hidden",
      }}
    >
      <header>
        <components.Navbar />
      </header>

      <main className={styles.main}>
        <div>
          <h3>ABOUT</h3>

          <aside className={styles.main_aside}>
            <span>
              The Gazette website is the official publication of the Federal
              Government of Nigeria. It is the primary source for Nigerian
              legislation and is managed by the National Institute for
              Legislative and Democratic Studies (NILDS). This first edition
              puts a focus on primary legislation from the time of civilian rule
              in Nigeria as of 1999. Acts will be published to the extent they
              are available. There are plans to expand the publication to build
              a Nigeria Government Gazette standalone website that holds all
              Gazette data.
              <br /> <br />
              This application derives its legal authority from the Federal
              Government Printer. Through this website, you may:
              <ul>
                <li>Read the Gazette,</li>
                <li>Search for and through Gazettes and</li>
                <li>
                  After creating a user account, download, bookmark and share
                  the Gazette - all free of cost.
                </li>
              </ul>
              <br />
              <br />
              This application derives its legal authority from the Federal
              Government Printer. Through the Gazette web application, you may: 
              access Nigerian legislation online, search for and through
              Nigerian legislation and upon registration, download, bookmark and
              share Nigerian legislation – at no cost. 
              <br /> <br />
            </span>

            <section>
              <div className={styles.gazette_pub_img}>
                <img src={about} alt="" />
              </div>
            </section>
          </aside>
        </div>

        <div className={styles.noLine}>
          <h3>OUR PARTNERS</h3>

          <aside>
            {/* <span>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex
              doloribus sit molestiae dolorem ad non veniam excepturi labore
              unde, temporibus error, consequatur incidunt at ipsum commodi iste
              ea autem quas? Lorem ipsum dolor, sit amet consectetur adipisicing
              elit. Ducimus quas corrupti ut consectetur! Sed accusantium animi
              explicabo in vitae, officia tempora alias placeat laborum omnis.
              <br /> <br />
              Aut voluptas saepe eveniet molestias! Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Quaerat ipsa libero voluptates
              distinctio vel atque sit, veritatis harum animi, dolor rerum
              tempore repellendus, accusantium commodi? Incidunt numquam illum
              quod expedita! Lorem ipsum dolor sit amet consectetur, adipisicing
              elit. Ex doloribus sit molestiae dolorem ad non veniam excepturi
              labore unde, temporibus error, consequatur incidunt at ipsum
              commodi iste ea autem quas? Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Ducimus quas corrupti ut
              consectetur! Sed accusantium animi explicabo in vitae, officia
              tempora alias placeat laborum omnis. Aut voluptas saepe eveniet
              molestias! Lorem ipsum dolor, sit amet consectetur adipisicing
              elit.
            </span> */}
          </aside>

          <div className={styles.section2}>
            <section></section>

            <aside className={styles.image_text}>
              <a href="https://nilds.gov.ng/" target="_blank" rel="noreferrer">
                <img
                  className={styles.nilds}
                  onMouseEnter={() => setNildsHover(true)}
                  onMouseLeave={() => setNildsHover(false)}
                  src={!nildsHover ? nilds_black : nilds}
                  alt="Nilds Logo"
                  style={{ cursor: "pointer" }}
                />
              </a>
              {/* <span>IN PARTNERSHIP WITH</span> */}

              <span>
                The National Institute for Legislative and Democratic Studies
                (NILDS) is an organ of the National Assembly established by an
                Act of Parliament. President Goodluck Jonathan signed into law
                the National Institute for Legislative Studies Act 2011 on March
                2nd 2011 following the passage of the same by the Senate and the
                House of Representatives.
                <br />
                <br />
                NILDS builds on the successes of the Policy Analysis and
                Research Project (PARP), established in 2003 as a capacity
                building institution of the National Assembly with the financial
                support of the African Capacity Building Foundation (ACBF). For
                over 7 years PARP has helped to strengthen the capacities of
                legislators and ensure that the positions and proposals advanced
                by the National Assembly are informed by requisite research and
                analytical support.
                <br />
                <br />
                In 2016, the Institute’s Governing Council approved the
                establishment of new department of Democratic Studies as well as
                a Department for Post Graduate Studies. On January 26th, 2018,
                President Muhammadu Buhari signed the National Institute for
                Legislative Studies (Amendment) Act, 2017. This expansion will
                enable the Institute to provide capacity development services to
                democratic Institutions and governance in Nigeria.
              </span>
            </aside>

            <aside className={`${styles.image_text} ${styles.nilds}`}>
              <div>
                {/* <span>IN PARTNERSHIP WITH</span> */}
                <a
                  href="https://www.kas.de/en/web/nigeria"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={styles.konrad}
                    onMouseEnter={() => setKonradHover(true)}
                    onMouseLeave={() => setKonradHover(false)}
                    src={!konradHover ? konrad_black : konrad}
                    alt="Konrad Logo"
                    // onClick={() =>
                    //   window.location.replace("https://www.kas.de/en/web/nigeria")
                    // }
                    style={{ cursor: "pointer" }}
                  />
                </a>
              </div>
              <span>
                Freedom, justice and solidarity are the basic principles
                underlying the work of the Konrad-Adenauer-Stiftung (KAS). KAS
                is a German political foundation, closely associated with the
                Christian Democratic Union (CDU), but financially and
                organizationally independent. As co-founder of the CDU and first
                Chancellor of the Federal Republic of Germany for over fourteen
                years (1949–1963), Konrad Adenauer (1876-1967) united
                Christian-social, conservative and liberal traditions in
                post-World War II Germany.
                <br />
                <br />
                In our European and international cooperation efforts KAS works
                for people to be able to live self-determined lives in freedom
                and dignity. The foundation’s contribution to this objective is
                value-based and helps Germany meet its growing responsibilities
                throughout the world.
                <br />
                <br />
                We cooperate with government institutions, political parties,
                civil society and academia, building strong partnerships along
                the way. In particular, we seek to intensify political
                cooperation in the area of international cooperation at national
                and international levels based on our objectives and values.
                Together with our partners we contribute to the creation of an
                international order that enables every country to develop in
                freedom and under its own responsibility.
                <br />
                <br />
                KAS operates 111 offices worldwide, with 20 country offices and
                regional programs in Sub-Saharan Africa alone. The Foundation
                has been present in Nigeria since 2002, focusing its work on
                strengthening the rule of law, good and democratic governance
                and security sector reform.
              </span>
            </aside>
          </div>
        </div>
      </main>

      <components.Footer />
    </div>
  );
}
