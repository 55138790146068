export const faqData = [
  {
    header: "What is a Gazette?",
    text: "A Gazette is an official publication for the purpose of notifying the actions and decisions of the government.",
  },
  {
    header: "What is the purpose of the Gazette.gov.ng?",
    text: "The Gazette.gov.ng is an official Government publication. Government uses it to publish acts that have been published by the National Assembly.",
  },
  {
    header: "What are the types of Gazettes?",
    text: "Gazettes are of two types Acts and Subsidiary regulations.",
  },
  {
    header: "What are the types of Gazettes?",
    text: "Gazettes are of two types Acts and Subsidiary regulations.",
  },
  {
    header: "Are the Acts and Regulations the official versions?",
    text: 'Yes, all Gazettes on the Website are "official", this means that they can be used for evidentiary purposes.',
  },
  {
    header: "How often is the website updated?",
    text: "The Gazettes.gov.ng website is generally updated every Month.",
  },
];
