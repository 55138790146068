import { baseUrl } from "../../config";

export class Request {
  constructor({ path, params }) {
    this.path = `${baseUrl}/${path}`;
    this.params = params;
  }

  async login() {
    const config = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.params),
    };

    try {
      const request = await fetch(this.path, config);
      const response = await request.json();

      if (!response.success) {
        return {
          ...response,
          status: false,
        };
      }

      if (
        response.token.original.user.role.name === "SuperAdmin" ||
        response.token.original.user.role.name === "Admin"
      ) {
        window.location.replace(
          `https://admin.gazettes.ng?admrdrt=${response.token.original.access_token}`
          // `http://localhost:3001?admrdrt=${response.token.original.access_token}`
        );
        return {
          redirect: true,
        };
      }

      const userString = JSON.stringify(response.token.original.user);

      sessionStorage.setItem("user", userString);
      sessionStorage.setItem("token", response.token.original.access_token);

      return {
        ...response,
        status: true,
      };
    } catch (error) {
      return {
        status: false,
        message: "An error occurred. Try again later",
      };
    }
  }
}
