import React, { useEffect, useState } from "react";
import { titleCase } from "title-case";
// import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

// import { User } from "../../contexts/user";
// import { baseUrl } from "../../config";

// import download_icon from "../../assets/download.svg";
// import bookmark_icon from "../../assets/bookmark.svg";
// import share_icon from "../../assets/share_icon_white.svg";

export default function Related({ data, pdfIsMaximized, setpdfIsMaximized }) {
  // const { setUserModal, setShareModal } = User();
  const [posts, setPosts] = useState([data.related_publications[0]]);
  const [index, setIndex] = useState(1);

  // const [bookmarked, setBookmarked] = useState(false);

  const loadMorePosts = () => {
    if (index >= 3) return window.location.replace("/search");

    if (index === 2) setpdfIsMaximized(false);

    setPosts(data.related_publications.slice(0, index + 1));
    setIndex(index + 1);
  };

  useEffect(() => {
    if (pdfIsMaximized) {
      setIndex(1);
      setPosts(data.related_publications.slice(0, 1));
    }
  }, [pdfIsMaximized]);

  // const setBookmark = async (id) => {
  //   const token = sessionStorage.getItem("token");

  //   if (!token) {
  //     sessionStorage.setItem("documentId", id);
  //     return setUserModal(true);
  //   }

  //   const config = {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       gazette_id: id,
  //     }),
  //   };

  //   try {
  //     const request = await fetch(`${baseUrl}/bookmarks`, config);
  //     const response = await request.json();

  //     if (response.success) return setBookmarked(true);
  //   } catch (error) {
  //     console.warn(error);
  //   }
  // };

  // const setShare = (slug) => {
  //   sessionStorage.setItem("slugLink", slug);
  //   setShareModal(true);
  // };

  // const handleDownload = async (id) => {
  //   const token = sessionStorage.getItem("token");

  //   const config = {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       gazette_id: id,
  //     }),
  //   };

  //   try {
  //     await fetch(`${baseUrl}/downloads`, config);
  //   } catch (error) {
  //     console.warn(error);
  //   }
  // };

  return (
    <div className={styles.related}>
      <h1>Related Publications</h1>

      <div>
        {posts?.map((item) => {
          return (
            <React.Fragment key={item.slug}>
              <a href={`/document/${item.slug}`}>
                <h5>{item.title}</h5>
                {/* <span>{item.category?.title}</span> */}
                <span className={styles.abstract}>
                  {titleCase(item.abstract)}
                </span>
              </a>
              {/* <div className={styles.action}>
                <button>
                  <a
                    href={`${item.file_url}`}
                    download
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleDownload(item.id)}
                  >
                    <img
                      src={download_icon}
                      alt="download icon"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </a>
                </button>
                <button
                  onClick={() => setBookmark(item.id)}
                  style={{ opacity: bookmarked && 0.5 }}
                >
                  {!bookmarked ? (
                    <img
                      src={bookmark_icon}
                      alt="bookmark icon"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  ) : (
                    <img
                      src={bookmark_icon}
                      alt="bookmark icon"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  )}
                </button>
                <button onClick={() => setShare(item.slug)}>
                  {" "}
                  <img
                    src={share_icon}
                    alt="share icon"
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </button>
              </div> */}
            </React.Fragment>
          );
        })}
      </div>
      {posts ? (
        <button className={styles.load_more} onClick={loadMorePosts}>
          {index < 3 ? "Load More" : "Search"}
        </button>
      ) : (
        <p>No Documents found</p>
      )}
    </div>
  );
}
