import { useState } from "react";
import validator from "validator";
import { toast } from "react-toastify";

import { User } from "../../contexts/user";
import { baseUrl } from "../../config";

import password_open from "../../assets/password_open.svg";
import password_close from "../../assets/password_close.svg";

import styles from "../../pages/signin/styles.module.scss";

export const SignupForm = ({ setState, action, page }) => {
  const { setUser } = User();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  const [passwordState, setPasswordState] = useState("password");
  const [confirmPasswordState, setConfirmPasswordState] = useState("password");

  const changeState = () => {
    setState("login");
  };

  const register = async () => {
    setProcessing(true);
    setError("");

    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setError("All fields are required");
      setProcessing(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setProcessing(false);
      return;
    }

    if (!validator.isEmail(email)) {
      setError("Please use a valid Email");
      setProcessing(false);
      return;
    }

    const config = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirmation: confirmPassword,
      }),
    };

    try {
      const request = await fetch(`${baseUrl}/create-account`, config);
      const response = await request.json();

      setProcessing(false);
      if (!response.success) return setError(response.message);

      setUser(response.data);

      toast.success("Created account successful");
      window.location.replace("/");
    } catch (error) {
      console.warn(error);
      setProcessing(false);
      setError("Could not create account. Please try again later");
    }
  };

  return (
    <div className={styles.signup}>
      <h3>REGISTER TODAY</h3>

      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <span className={styles.form_error}>{error}</span>
          <label htmlFor="">First Name</label>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <br />

          <label htmlFor="">Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <br />

          <label htmlFor="">Email</label>
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <br />

          <label htmlFor="">Password</label>
          <div className={styles.form_login_box}>
            <input
              type={passwordState}
              placeholder="**************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.form_login_box_input}
            />
            {passwordState === "password" && (
              <img
                src={password_close}
                alt="show password"
                onClick={() => setPasswordState("text")}
              />
            )}
            {passwordState === "text" && (
              <img
                src={password_open}
                alt="hide password"
                onClick={() => setPasswordState("password")}
              />
            )}
          </div>

          <label htmlFor="">Confirm Password</label>
          <div className={styles.form_login_box}>
            <input
              type={passwordState}
              placeholder="**************"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={styles.form_login_box_input}
            />
            {confirmPasswordState === "password" && (
              <img
                src={password_close}
                alt="show password"
                onClick={() => setConfirmPasswordState("text")}
              />
            )}
            {confirmPasswordState === "text" && (
              <img
                src={password_open}
                alt="hide password"
                onClick={() => setConfirmPasswordState("password")}
              />
            )}
          </div>

          {!processing ? (
            <button
              className={styles.form_submit}
              type="submit"
              onClick={register}
            >
              Register
            </button>
          ) : (
            <span className={styles.form_processing}>Creating account...</span>
          )}

          <span>
            Don't have an account? <button onClick={changeState}>Login</button>
          </span>
        </form>
      </div>
    </div>
  );
};
