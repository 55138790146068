import { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import styles from "./styles.module.scss";

import { BiMicrophone } from "react-icons/bi";

// import SearchIconGrey from "../../assets/search_icon_grey.svg";
import SearchIconWhite from "../../assets/search_icon_white.svg";

export default function Search() {
  const { transcript, resetTranscript } = useSpeechRecognition();

  const [searchTerm, setSearchTerm] = useState("");
  const [isListening, setIsListening] = useState({
    mic: false,
    defaultRender: true,
  });

  const search = () => {
    window.location.replace(`/search?q=${searchTerm}&type=keywords`);
  };

  const checkKeyPress = (e) => {
    if (e.which === 13)
      return window.location.replace(`/search?q=${searchTerm}&type=keywords`);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    params.q && setSearchTerm(params.q);
    params.month && setSearchTerm("");
  }, []);

  useEffect(() => {
    if (isListening.mic || isListening.defaultRender) return;

    window.location.replace(`/search?q=${transcript}&type=keywords`);
  }, [isListening, transcript]);

  const startRecording = () => {
    if (isListening.mic) {
      return setIsListening({ mic: true, defaultRender: false });
    }

    try {
      resetTranscript();

      (() => {
        setIsListening({ mic: true, defaultRender: false });
        SpeechRecognition.startListening({
          continuous: true,
        });
      })();

      const stopMic = function () {
        setIsListening({ mic: false, defaultRender: false });
        SpeechRecognition.stopListening();
      };

      setTimeout(() => {
        stopMic();
      }, 5000);
    } catch (error) {}
  };

  return (
    <div className={styles.search}>
      <div className={styles.search_component}>
        <div className={styles.search_input}>
          <button className={isListening.mic ? styles.listening : null}>
            <BiMicrophone onClick={startRecording} color="#fff" size={20} />
          </button>
          <input
            type="text"
            placeholder="Search by title, keyword or year"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => checkKeyPress(e)}
          />
          <button onClick={search}>
            <img src={SearchIconWhite} alt="Search Icon" />
          </button>
        </div>

        <div className={styles.search_trigger}>
          {/* <button>ADVANCED SEARCH</button> */}
        </div>
      </div>
    </div>
  );
}
