import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ReactGA from "react-ga";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

import prev_icon from "../../assets/prev_page.svg";
import next_icon from "../../assets/next_page.svg";

import Navbar from "../../components/navbar";
// import Topnav from "../../components/topnav";

import delete_icon from "../../assets/delete_icon.svg";

import { User as UserObject } from "../../contexts/user";

import { baseUrl } from "../../config";

import styles from "./styles.module.scss";
// import Loader from "../../components/loader";
import Footer from "../../components/footer";

export default function Profile() {
  const navigate = useNavigate();
  const { user, setUser } = UserObject();

  const [tab, setTab] = useState(null);
  const [data, setData] = useState([]);

  const [filterValue, setFilterValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [showCount, setShowCount] = useState(10);
  const [sortBy, setSortBy] = useState("desc");

  // const [nextUrl, setNextUrl] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_by: sortBy,
        page_number: showCount,
      }),
    };

    try {
      const request = await fetch(`${baseUrl}/get-${tab}?page=${page}`, config);
      const response = await request.json();

      console.log(response);

      if (response.success || response.data) {
        setData(response.data);
        setFilteredData(response.data);
        setPageCount(response.meta.last_page);
      } else {
        setData([]);
        setFilteredData([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [tab, showCount, sortBy, page]);

  const filterData = useCallback(async () => {
    if (!filterValue) return;

    const filter = data.filter((item) => {
      if (
        item.gazette.title.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.gazette.category.title
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    setFilteredData(filter);
  }, [data, filterValue]);

  useEffect(() => {
    document.title = "Profile - Nigerian Gazettes";
    ReactGA.initialize("UA-215525316-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (!tab) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      params.tab ? setTab(params.tab) : setTab("bookmarks");
      return;
    }

    fetchData();
  }, [tab, fetchData]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const deleteData = async (id) => {
    const token = sessionStorage.getItem("token");
    const config = {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const request = await fetch(`${baseUrl}/${tab}/${id}`, config);
      const response = await request.json();

      if (response.success) {
        fetchData();
      }
    } catch (error) {
      console.warn(error);
      setData([]);
    }
  };

  const logout = async () => {
    const token = sessionStorage.getItem("token");

    const id = toast.loading("Logging out...");

    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    };

    try {
      const request = await fetch(`${baseUrl}/logout`, config);
      const response = await request.json();

      if (response.success) {
        navigate("/");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        setUser(null);

        toast.update(id, {
          render: "Logged out",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
        return;
      }

      toast.update(id, {
        render: "Could not log you out. Try again later",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (error) {
      console.warn(error);
      toast.update(id, {
        render: "An error occurred. Try again later",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const nextLabel = <img src={next_icon} alt="next" />;
  const prevLabel = <img src={prev_icon} alt="prev" />;

  return (
    <div className={styles.profile}>
      <header>
        <Navbar />
      </header>

      <main>
        <div className={styles.header}>{/* <h4>PROFILE</h4> */}</div>

        <div className={styles.profile_main}>
          <div className={styles.profile_info}>
            {/* <img src={User} alt="User" /> */}

            <div className={styles.user_box}>
              <h4>User Information</h4>
              <div className={styles.user_box_inner}>
                <table style={{ border: "1px solid black" }}>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        <b>
                          {user?.first_name} {user?.last_name}
                        </b>{" "}
                      </td>
                    </tr>

                    <tr>
                      <td>Email</td>
                      <td>
                        <b>{user?.email}</b>{" "}
                      </td>
                    </tr>

                    <tr>
                      <td>Designation</td>
                      <td>
                        <b>User</b>
                      </td>
                    </tr>

                    <tr>
                      <td>Date registered</td>
                      <td>{dayjs(user?.created_at).format("DD MMMM YYYY")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={styles.edit_profile}>
                <button onClick={logout}>LOGOUT</button>
              </div>
            </div>
          </div>

          <div className={styles.profile_docs}>
            <div className={styles.profile_tabs}>
              <button
                onClick={() => setTab("bookmarks")}
                style={{
                  color: tab === "bookmarks" ? "#129017" : "#c4c4c4",
                  backgroundColor: tab !== "bookmarks" && "#f5f5f5",
                }}
              >
                Bookmarked
              </button>
              <button
                onClick={() => setTab("views")}
                style={{
                  color: tab === "views" ? "#129017" : "#c4c4c4",
                  backgroundColor: tab !== "views" && "#f5f5f5",
                }}
              >
                Viewed
              </button>
              <button
                onClick={() => setTab("downloads")}
                style={{
                  color: tab === "downloads" ? "#129017" : "#c4c4c4",
                  backgroundColor: tab !== "downloads" && "#f5f5f5",
                }}
              >
                Downloaded
              </button>
            </div>

            <div
              // style={{ backgroundColor: "red" }}
              className={styles.profile_pagination}
            >
              <input
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                type="text"
                placeholder="Search by title, keyword or year"
              />

              <section>
                <div>
                  <span>Sort by: </span>
                  <select
                    name=""
                    id=""
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    <option value="asc">Newest to oldest</option>
                    <option value="desc">Oldest to newest</option>
                  </select>
                </div>

                <div>
                  <span>Show:</span>
                  <select
                    name=""
                    id=""
                    onChange={(e) => setShowCount(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">50</option>
                  </select>
                </div>
              </section>
            </div>

            <div className={styles.profile_table}>
              {filteredData.length > 0 && !loading && (
                <table>
                  <tr>
                    <th>S/NO.</th>
                    <th>DOCUMENT NAME</th>
                    <th>CATEGORY</th>
                    <th>DATE OF ASSENT</th>
                    {tab === "bookmarks" && <th>DATE BOOKMARKED</th>}
                    {tab === "views" && <th>DATE VIEWED</th>}
                    {tab === "downloads" && <th>DATE DOWNLOADED</th>}
                    <th>ACTIONS</th>
                  </tr>

                  {filteredData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={`/document/${item.gazette.slug}`}>
                            {item?.gazette.title}
                          </Link>
                        </td>
                        <td>{item.gazette.category?.title}</td>
                        <td>
                          {dayjs(item.gazette.published_date).format(
                            "DD MMMM YYYY"
                          )}
                        </td>
                        <td>{dayjs(item.created_at).format("DD MMMM YYYY")}</td>
                        <td onClick={() => deleteData(item.id)}>
                          <img src={delete_icon} alt="Delete" />
                        </td>
                      </tr>
                    );
                  })}
                </table>
              )}

              {filteredData.length === 0 && !loading && (
                <div>
                  <span>
                    No Gazettes found. <Link to="/search">Bookmark</Link> /{" "}
                    <Link to="/search">View</Link> /{" "}
                    <Link to="/search">Download</Link> some Gazettes and they
                    will be listed here.
                  </span>
                </div>
              )}

              {loading && (
                <div>
                  <span>Fetching Data... </span>
                </div>
              )}
            </div>

            {pageCount && (
              <div className={styles.page_pagination}>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={nextLabel}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={prevLabel}
                  renderOnZeroPageCount={null}
                  // pageClassName="page-item"
                  // pageLinkClassName="page-link"
                  // previousClassName="page-item"
                  // previousLinkClassName="page-link"
                  // nextClassName="page-item"
                  // nextLinkClassName="page-link"
                  // breakClassName="page-item"
                  // breakLinkClassName="page-link"
                  // containerClassName="pagination"
                  activeClassName={styles.active_page}
                />
              </div>
            )}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
