import Help from "./help";
import Home from "./home";
import About from "./about";
import FAQ from "./faq";
import Signin from "./signin";
import Search from "./search";
import Gazette from "./gazette";
import Profile from "./profile";
import Repository from "./repository";
import Publications from "./publications";

const pages = {
  Help,
  Home,
  About,
  Signin,
  Search,
  Gazette,
  Profile,
  Repository,
  FAQ,
  Publications,
};

export default pages;
