import { useState, createContext, useContext } from "react";

export const SearchContext = createContext();

export const SearchModal = () => {
  const [openSearch, setOpenSearch] = useContext(SearchContext);
  return { openSearch, setOpenSearch };
};

export const SearchProvider = ({ children }) => {
  const [openSearch, setOpenSearch] = useState(false);

  return (
    <SearchContext.Provider value={[openSearch, setOpenSearch]}>
      {children}
    </SearchContext.Provider>
  );
};
