import { Link } from "react-router-dom";

import { User } from "../../contexts/user";

import Close from "../../assets/close.svg";
import styles from "./styles.module.scss";

export default function SigninModal() {
  const { setUserModal } = User();

  const pathname = window.location.pathname;

  return (
    <div className={styles.signin}>
      <div className={styles.signin_modal}>
        <div className={styles.signin_modal_header}>
          <h4>BOOKMARK</h4>
          <img
            src={Close}
            alt="Close Modal"
            onClick={() => setUserModal(false)}
          />
        </div>

        <p>
          Sorry, You must be a logged in user to be able to bookmark gazettes
        </p>

        <div className={styles.signin_modal_buttons}>
          <Link to={`/signin?action=bookmark&page=${pathname}`}>
            <button onClick={() => setUserModal(false)}>LOGIN</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
