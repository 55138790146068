import styles from "./styles.module.scss";

import { adminUrl } from "../../config";

export default function Topnav() {
  return (
    <div className={styles.topnav}>
      <a href={`${adminUrl}`}>Admin Login</a>
    </div>
  );
}
