import { useState, useEffect, createContext, useContext } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../config";

export const UserContext = createContext();

export const User = () => {
  const [user, setUser, userModal, setUserModal, shareModal, setShareModal] =
    useContext(UserContext);
  return { user, setUser, userModal, setUserModal, shareModal, setShareModal };
};

export const Bookmark = async (id) => {
  const { user } = User();
  if (!user) return;

  const config = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      gazette_id: id,
    }),
  };

  try {
    await fetch(`${baseUrl}/bookmarks`, config);
  } catch (error) {
    console.warn(error);
  }
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const userString = sessionStorage.getItem("user");
    const userJSON = JSON.parse(userString);
    setUser(userJSON);

    const sessionToken = sessionStorage.getItem("rdrToken");

    if (sessionToken) return setToken(sessionToken);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let token;

    if (params.verifyToken) {
      (async () => {
        try {
          const config = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: params.verifyToken,
            }),
          };

          const request = await fetch(`${baseUrl}/verify-account`, config);

          const response = await request.json();

          if (response.success) {
            toast.success("Account verified");
          } else {
            toast.error("Could not verify account");
          }
        } catch (error) {
          toast.error("Could not verify account");
        }
      })();
    }

    if (!params.rdrToken && !params.token) {
      return window.location.replace("https://gazettes.ng");
    }

    if (params.token) token = params.token;
    if (params.rdrToken) token = params.token;

    (async () => {
      const config = {
        method: "GET",
      };

      const request = await fetch(`${baseUrl}/token-verify/${token}`, config);

      const response = await request.json();

      if (response.status) {
        sessionStorage.setItem("rdrToken", params.token);
        return setToken(params.token);
      }

      setToken(true);
      window.location.replace("https://gazettes.ng");
    })();
  }, []);

  if (!token) return null;

  return (
    <UserContext.Provider
      value={[
        user,
        setUser,
        userModal,
        setUserModal,
        shareModal,
        setShareModal,
      ]}
    >
      {children}
    </UserContext.Provider>
  );
};
