import pages from "../pages";

const routes = [
  {
    path: "/",
    element: <pages.Home />,
    type: "public",
  },
  {
    path: "/document/:id",
    element: <pages.Gazette />,
    type: "public",
  },
  {
    path: "/search",
    element: <pages.Search />,
    type: "public",
  },
  {
    path: "/about",
    element: <pages.About />,
    type: "public",
  },
  {
    path: "/publications",
    element: <pages.Publications />,
    type: "public",
  },
  {
    path: "/faq",
    element: <pages.FAQ />,
    type: "public",
  },
  {
    path: "/profile",
    element: <pages.Profile />,
    type: "authenticated",
  },
  {
    path: "/signin",
    element: <pages.Signin />,
    type: "protected",
  },
];

export default routes;
