import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { storeLocationData } from "../../helpers/analytics";

import {
  ForgotPassword,
  LoginForm,
  SignupForm,
  ResetPassword,
} from "../../components/auth";
import Footer from "../../components/footer";

import Logo from "../../assets/Logo.png";
import styles from "./styles.module.scss";

export default function Signin() {
  const [state, setState] = useState("login");

  const [action, setAction] = useState(null);
  const [page, setPage] = useState(null);

  const [pwdToken, setPwdToken] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    storeLocationData();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.action ? setAction(params.action) : setAction("");
    params.page ? setPage(params.page) : setPage("");
    params.pwd_token && setPwdToken(params.pwd_token);
    params.state && setState(params.state);
    params.email && setEmail(params.email);

    document.title = "Signin - Nigerian Gazettes";
  }, []);

  return (
    <>
      <div className={styles.signin}>
        <main>
          <div className={styles.logo_landing}>
            <Link to="/">
              <img src={Logo} alt="Website Logo" />
            </Link>
            <p>
              Welcome to the Official Gazette Publication of the Federal
              Republic of Nigeria from 1999 till date. This is a collaboration
              between the National Institute for Legislative Studies & Konrad
              Adenauer Stiftung and it is accurate, legitimate, valid and in
              accordance with the Constitution of the Federal Republic of
              Nigeria in order to bring the law closer to the people.
            </p>
          </div>

          <div>
            {state === "login" ? (
              <LoginForm setState={setState} page={page} action={action} />
            ) : state === "reset" ? (
              <ForgotPassword setState={setState} />
            ) : state === "resetpwd" ? (
              <ResetPassword
                setState={setState}
                page={page}
                action={action}
                pwdToken={pwdToken}
                email={email}
              />
            ) : (
              <SignupForm setState={setState} page={page} action={action} />
            )}
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}
