import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Carousel } from "react-responsive-carousel";
// import { sentenceCase } from "sentence-case";

import Loader from "../loader";

import { User } from "../../contexts/user";
import { baseUrl } from "../../config";

// import { AppGraph } from "../graph";

// import Gazette_Collage from "../../assets/gazette_collage.png";
import Slider1 from "../../assets/slider-image1.jpg";
// import Slider2 from "../../assets/slider-image2.jpg";
import Slider3 from "../../assets/slider-image3.webp";

import share_icon from "../../assets/share_icon.svg";
// import { graph } from "../../static/graph";

import styles from "./styles.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Landing() {
  const { setShareModal } = User();
  const [gazettes, setGazettes] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetch_latest_gazettes = async () => {
    try {
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "T#uUJ7+%U:5%PY}4",
        },
        body: JSON.stringify({
          // keywords: [keywords],
        }),
      };

      const request = await fetch(`${baseUrl}/search`, config);

      const response = await request.json();
      return response;
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    (async () => {
      const data = await fetch_latest_gazettes();

      setLoading(false);
      if (!data) return;
      setGazettes(data.data.slice(0, 3));
    })();
  }, []);

  return (
    <div className={styles.landing}>
      <div className={styles.map}>
        {/* <img src={Gazette_Collage} alt="Gazette Collage" /> */}
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          interval={3000}
          showThumbs={false}
        >
          <div>
            <img src={Slider1} alt="slider 1" />
          </div>
          {/* <div>
            <img src={Slider2} alt="slider 2" />
          </div> */}
          <div>
            <img src={Slider3} alt="slider 3" />
          </div>
        </Carousel>
      </div>

      <div className={styles.recent_posts}>
        <h3>MOST RECENT</h3>

        <div className={styles.posts}>
          {gazettes &&
            gazettes.map((gazette) => {
              // console.log(gazette);
              return (
                <div className={styles.post} key={gazette.id}>
                  <div className={styles.publish_share}>
                    <div>
                      {/* <span>
                        Date of Assent:{" "}
                        {dayjs(gazette.ascent_date).format("DD MMMM, YYYY")}
                      </span>
                      <br />
                      <span>
                        Date of Publication:{" "}
                        {dayjs(gazette.published).format("DD MMMM, YYYY")}
                      </span>{" "} */}
                    </div>
                    <img
                      src={share_icon}
                      alt="Share"
                      onClick={() => {
                        sessionStorage.setItem("slugLink", gazette.slug);
                        setShareModal(true);
                      }}
                    />
                  </div>
                  <Link to={`/document/${gazette.slug}`}>
                    <h4>{gazette.title}</h4>
                    <p>{gazette.abstract}</p>
                  </Link>
                  <div className={styles.publish_dates}>
                    <span>
                      Date of Assent:{" "}
                      {dayjs(gazette.ascent_date).format("DD MMMM, YYYY")}
                    </span>
                    <span>
                      Date of Publication:{" "}
                      {dayjs(gazette.published).format("DD MMMM, YYYY")}
                    </span>{" "}
                  </div>
                </div>
              );
            })}
        </div>

        {loading && <Loader />}
      </div>
    </div>
  );
}
