import React from "react";
import Navbar from "../../components/navbar";
import Search from "../../components/search";
import Topnav from "../../components/topnav";

export default function Repository() {
  return (
    <div>
      <header>
        <Topnav />
        <Navbar />
        <Search />
      </header>

      <main>Repository Page</main>
    </div>
  );
}
