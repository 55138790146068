import { useState } from "react";

import { FeedbackModal } from "../../contexts/feedback_modal";

import nilds from "../../assets/nilds.svg";
import konrad from "../../assets/konrad.jpg";

import nilds_black from "../../assets/nilds_black.svg";
import konrad_black from "../../assets/konrad_black.jpg";

import styles from "./styles.module.scss";

export default function Footer() {
  const { openFeedback, setOpenFeedback } = FeedbackModal();

  const [nildsHover, setNildsHover] = useState(false);
  const [konradHover, setKonradHover] = useState(false);

  return (
    <div className={styles.footer}>
      {/* <a href="https://forms.gle/SFwEweTiBNusP2eF8"> */}
      <div
        className={styles.feedback}
        onClick={() => setOpenFeedback(!openFeedback)}
      >
        <span>Feedback</span>
      </div>
      {/* </a> */}

      <div className={styles.copy}>
        <span>
          Copyright &copy; {new Date().getFullYear()} | All rights reserved
        </span>
      </div>

      <div>
        <a href="https://nilds.gov.ng/" target="_blank" rel="noreferrer">
          <img
            className={styles.nilds}
            onMouseEnter={() => setNildsHover(true)}
            onMouseLeave={() => setNildsHover(false)}
            src={!nildsHover ? nilds_black : nilds}
            alt="Nilds Logo"
            style={{ cursor: "pointer" }}
          />
        </a>
        {/* <span>IN PARTNERSHIP WITH</span> */}
        <a
          href="https://www.kas.de/en/web/nigeria"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={styles.konrad}
            onMouseEnter={() => setKonradHover(true)}
            onMouseLeave={() => setKonradHover(false)}
            src={!konradHover ? konrad_black : konrad}
            alt="Konrad Logo"
            // onClick={() =>
            //   window.location.replace("https://www.kas.de/en/web/nigeria")
            // }
            style={{ cursor: "pointer" }}
          />
        </a>
      </div>
    </div>
  );
}
