import React from "react";
// import { BiCookie } from "react-icons/bi";

import styles from "./styles.module.scss";

export default function Cookie({ setCookieAccepted }) {
  return (
    <div className={styles.container}>
      <div className={styles.container__text}>
        <span>
          At Gazettes.ng, we respect your concerns about privacy. Please note
          that this website uses cookies to improve your experience. By clicking
          accept, you agree to use our cookies and other technologies.
        </span>
      </div>

      <div className={styles.container__btn}>
        <button
          onClick={() => {
            localStorage.setItem("cookie-accepted", "true");
            setCookieAccepted(true);
          }}
        >
          ACCEPT
        </button>
      </div>
    </div>
  );
}
