import { useState } from "react";
import { toast } from "react-toastify";

import { baseUrl } from "../../config";

import styles from "../../pages/signin/styles.module.scss";

export const ForgotPassword = ({ setState }) => {
  const [email, setEmail] = useState("");

  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  const forgot_password = async () => {
    setProcessing(true);

    if (!email) {
      setError("Email is required");
      return setProcessing(false);
    }
    try {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
        }),
      };

      const request = await fetch(`${baseUrl}/forgot-password`, options);
      const response = await request.json();

      console.log(response);

      if (response.success) {
        setError("");

        setProcessing(false);
        return toast.success("A message has been sent to your email");
      }

      setError(response.message);
      setProcessing(false);
    } catch (error) {}
  };

  return (
    <div className={styles.login}>
      <h3>RESET PASSWORD</h3>

      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <span className={styles.form_error}>{error}</span>
          <label htmlFor="">Email</label>
          <input
            type="text"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <br />

          {!processing ? (
            <button
              onClick={forgot_password}
              className={styles.form_submit}
              type="submit"
            >
              Reset
            </button>
          ) : (
            <span className={styles.form_processing}>Sending email...</span>
          )}

          <span>
            Have an account?{" "}
            <button onClick={() => setState("login")}>Login</button>
          </span>
        </form>
      </div>
    </div>
  );
};
