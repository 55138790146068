import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

import { baseUrl } from "../../config";

import styles from "./styles.module.scss";

export default function Filters({
  props,
  results,
  showFilters,
  setShowFilters,
  setFilteredResults,
}) {
  const years = [
    1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010,
    2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
  ];

  const monthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const lastYear = years[years.length - 1];
  const currentYear = parseInt(new Date().getFullYear());
  const missingYears = currentYear - lastYear;

  for (let i = 1; i <= missingYears; i++) {
    years.push(lastYear + i);
  }

  const [_, setTags] = useState([]);
  const [volume, setVolume] = useState("");
  const [dbcategories, setDbCategories] = useState([]);
  const [dbVolumes, setDbVolumes] = useState([]);

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const updateYears = (e) => {
    const { filters } = props;
    const { years, setYears } = filters;

    if (e.target.checked) {
      setYears([...years, e.target.name]);
      return;
    }

    const index = years.indexOf(e.target.name);
    setYears([...years.splice(0, index), ...years.splice(index + 1)]);
  };

  const updateCategories = (e) => {
    const { filters } = props;
    const { categories, setCategories } = filters;

    if (e.target.checked) {
      setCategories([...categories, e.target.name]);
      return;
    }

    const index = categories.indexOf(e.target.name);
    setCategories([
      ...categories.splice(0, index),
      ...categories.splice(index + 1),
    ]);
  };

  // const updateTags = (tag) => {
  //   const { filters } = props;
  //   const { tags, setTags } = filters;

  //   const index = tags.indexOf(tag.name);

  //   if (index === -1) {
  //     setTags([...tags, tag.name]);
  //     return;
  //   }

  //   setTags([...tags.splice(0, index), ...tags.splice(index + 1)]);
  // };

  const clearTags = () => {
    const { filters } = props;
    const { setTags } = filters;

    setTags([]);
    setVolume("");
  };

  useEffect(() => {
    if (window.innerWidth < 1200) setShowFilters(false);

    (async () => {
      try {
        const config = {
          method: "GET",
        };
        const request = await fetch(`${baseUrl}/tags`, config);
        const response = await request.json();

        setTags(response.data);
      } catch (error) {
        console.warn(error);
      }
    })();

    (async () => {
      try {
        const config = {
          method: "GET",
        };
        const request = await fetch(`${baseUrl}/categories`, config);
        const response = await request.json();

        setDbCategories(response.data);
      } catch (error) {
        console.warn(error);
      }
    })();

    (async () => {
      try {
        const config = {
          method: "GET",
        };
        const request = await fetch(`${baseUrl}/get-volumes`, config);
        const response = await request.json();

        setDbVolumes(response.volumes);
      } catch (error) {
        console.warn(error);
      }
    })();
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.month && setMonth(params.month);
    params.year && setYear(params.year);
  }, []);

  useEffect(() => {
    if (!volume) return setFilteredResults(results);

    const filteredResults = results.filter((item) => {
      if (item.volume === volume) {
        return true;
      }

      return false;
    });

    setFilteredResults(filteredResults);
  }, [volume, results, setFilteredResults]);

  return (
    <div className={styles.filters}>
      <div className={styles.toggle}>
        <button
          style={{ backgroundColor: showFilters ? "red" : "green" }}
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? "Hide" : "Show"} Filters
        </button>
      </div>

      {showFilters && (
        <>
          <div className={styles.header}>
            <h3>Filter</h3>
            <button
              onClick={() => {
                props.filters.setYears([]);
                props.filters.setCategories([]);
                clearTags();
              }}
            >
              clear all
            </button>
          </div>

          <div className={styles.dateTags}>
            {month && (
              <div>
                <React.Fragment>
                  <button
                    style={{
                      // backgroundColor: "#129017",
                      color: "#fff",
                    }}
                  >
                    {`${monthMap[month]}, ${year}`}
                    <AiOutlineClose onClick={() => setMonth(null)} />
                  </button>
                </React.Fragment>
              </div>
            )}

            {/* <div>
              {tags.map((tag) => {
                return (
                  <React.Fragment key={tag.name}>
                    <button
                      style={{
                        backgroundColor:
                          props.filters.tags.includes(tag.name) && "#129017",
                        color: props.filters.tags.includes(tag.name) && "#fff",
                      }}
                      onClick={() => updateTags(tag)}
                    >
                      {tag.name}
                    </button>
                  </React.Fragment>
                );
              })}
            </div> */}
          </div>

          <div className={styles.categories}>
            <h3>Categories</h3>

            <div>
              {dbcategories.map((category) => {
                return (
                  <React.Fragment key={category.title}>
                    <input
                      type="checkbox"
                      id={category.title}
                      name={category.title}
                      onChange={(e) => {
                        updateCategories(e);
                      }}
                      checked={props.filters.categories.includes(
                        category.title
                      )}
                    />
                    <label htmlFor={category.title}>{category.title}</label>
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          <div className={styles.date}>
            <h3>Year of Assent</h3>

            <div>
              {years.reverse().map((yr) => {
                return (
                  <div key={yr}>
                    <input
                      type="checkbox"
                      id={yr}
                      name={yr}
                      onChange={(e) => {
                        if (
                          props.filters.years.includes(yr.toString()) ||
                          yr.toString() === year
                        ) {
                        }
                        updateYears(e);
                      }}
                      defaultChecked={
                        props.filters.years.includes(yr.toString()) ||
                        yr.toString() === year
                      }
                    />
                    <label htmlFor={yr}>{yr}</label>
                    <br />
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.tags}>
            {/* <div>
              {tags.map((tag) => {
                return (
                  <React.Fragment key={tag.name}>
                    <button
                      style={{
                        backgroundColor:
                          props.filters.tags.includes(tag.name) && "#129017",
                        color: props.filters.tags.includes(tag.name) && "#fff",
                      }}
                      onClick={() => updateTags(tag)}
                    >
                      {tag.name}
                    </button>
                  </React.Fragment>
                );
              })}
            </div> */}
          </div>

          <div className={styles.volume}>
            <h3>Volume no.</h3>

            <div>
              <select
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
                name=""
                id=""
              >
                <option value="">Select a Volume no.</option>
                {/* <option value=""></option> */}
                {/* {dbVolumes.map} */}
                {dbVolumes &&
                  dbVolumes.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className={styles.filter_button}>
            <button onClick={props.filterResults}>Filter</button>
          </div>
        </>
      )}
    </div>
  );
}
