import components from "../../components";

import ReactGA from "react-ga";
import { useEffect } from "react";

import styles from "./styles.module.scss";

export default function Home() {
  useEffect(() => {
    document.title = "Home - Nigerian Gazettes";
    ReactGA.initialize("UA-215525316-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className={styles.landing}>
      <header>
        <components.Navbar />
      </header>

      <main className={styles.landing_main}>
        <div className={styles.column}>
          <components.Landing />
          <components.Graph />
        </div>

        <section className={styles.landing_footer}>
          <components.Footer />
        </section>
      </main>
    </div>
  );
}
