import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./styles.module.scss";
import Navbar from "../../components/navbar";

import { baseUrl } from "../../config";

import Document from "../../components/gazette";

// import { baseUrl } from "../../config";
import Loader from "../../components/loader";
import Related from "../../components/related";
// import Topnav from "../../components/topnav";

import ReactGA from "react-ga";

export default function Gazette() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [extra, setExtra] = useState(null);
  const [loading, setLoading] = useState(true);

  const [pdfIsMaximized, setpdfIsMaximized] = useState(false);

  useEffect(() => {
    ReactGA.initialize("UA-215525316-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    (async () => {
      try {
        const config = {
          method: "GET",
        };
        const request = await fetch(`${baseUrl}/gazettes/${id}`, config);
        const response = await request.json();

        setData(response.data);
        setExtra(response.extra);
        setLoading(false);

        document.title = `${response.data.title} - Nigerian Gazette`;
      } catch (error) {
        console.warn(error);
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <div className={styles.gazette}>
      {!loading ? (
        <>
          <header>
            <Navbar />
          </header>

          <main className={styles.main}>
            {data && (
              <>
                <Document
                  data={data}
                  setpdfIsMaximized={setpdfIsMaximized}
                  pdfIsMaximized={pdfIsMaximized}
                />
                <Related
                  data={extra}
                  setpdfIsMaximized={setpdfIsMaximized}
                  pdfIsMaximized={pdfIsMaximized}
                />
              </>
            )}
          </main>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
