import { useState } from "react";
import "./app.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import application routes
import AppRoutes from "./routes";

import { User } from "./contexts/user";
import { SearchModal as OpenSearch } from "./contexts/search_modal";
import { FeedbackModal } from "./contexts/feedback_modal";

import Share from "./components/share";
import SigninModal from "./components/signin";
import SearchModal from "./components/search/modal";
import Feedback from "./components/feedback";
import Cookie from "./components/cookieConsent";

function App() {
  const { userModal, shareModal } = User();
  const { openSearch } = OpenSearch();
  const { openFeedback } = FeedbackModal();

  const [cookieAccepted, setCookieAccepted] = useState(
    localStorage.getItem("cookie-accepted")
  );

  return (
    <div className="app">
      {/* Configure Toast container  */}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />

      {!cookieAccepted && <Cookie setCookieAccepted={setCookieAccepted} />}

      {/* Feedback form for testers  */}

      <Router>
        {openSearch && <SearchModal />}
        {userModal && <SigninModal />}
        {shareModal && <Share />}
        {openFeedback && <Feedback />}

        <AppRoutes />
      </Router>
    </div>
  );
}

export default App;
