import { useState } from "react";
import styles from "./modal_styles.module.scss";

import { SearchModal } from "../../contexts/search_modal";

export default function Modal() {
  const { setOpenSearch } = SearchModal();
  const [searchTerm, setSearchTerm] = useState("");

  // const search = () => {
  //   window.location.replace(`/search?q=${searchTerm}&type=keywords`);
  // };

  const checkKeyPress = (e) => {
    if (e.which === 13)
      return window.location.replace(`/search?q=${searchTerm}&type=keywords`);
  };

  const checkClickEvent = (event) => {
    if (event.target.id !== "searchbar") setOpenSearch(false);
  };

  return (
    <div className={styles.modal} onClick={(e) => checkClickEvent(e)}>
      <div className={styles.modal_input}>
        <input
          autoFocus
          id="searchbar"
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => checkKeyPress(e)}
        />
      </div>
    </div>
  );
}
