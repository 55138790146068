import React, { Fragment, useState, useEffect } from "react";
import { AiFillStar, AiOutlineClose } from "react-icons/ai";

import { toast } from "react-toastify";

import { baseUrl } from "../../config";
import { FeedbackModal } from "../../contexts/feedback_modal";

import styles from "./styles.module.scss";

export default function Feedback() {
  const { setOpenFeedback } = FeedbackModal();

  const [email] = useState("response@gazettes.ng");
  const [websiteExperience, setWebsiteExperience] = useState(null);
  const [device, setDevice] = useState(null);
  const [websiteResponse, setWebsiteResponse] = useState(null);
  const [searchOutcome, setSearchOutcome] = useState(null);
  const [featuresCompleted] = useState("Default");

  const submitFeedback = async () => {
    try {
      const config = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          website_experience: websiteExperience,
          device,
          website_response: websiteResponse,
          relevant_outcome: searchOutcome,
          features_completed: featuresCompleted,
        }),
      };

      const id = toast.loading("Submitting Feedback...");

      const request = await fetch(`${baseUrl}/feedbacks`, config);
      const response = await request.json();

      if (request.status !== 200) {
        return toast.update(id, {
          render: response.message || "An error occurred. Try again later",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      }

      toast.update(id, {
        render: "Feedback Submitted",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });

      setOpenFeedback(false);
    } catch (error) {
      return error;
    }
  };

  return (
    <div className={styles.feedback_container}>
      <div className={styles.feedback}>
        <h3>Hello,</h3>

        <p>
          We want to know your experience on the website, this survey would take
          less than 2 minutes.
          <br /> <br />
          Thank you for your response.
        </p>

        <div className={styles.close__feedback}>
          <AiOutlineClose
            size={20}
            onClick={() => setOpenFeedback(false)}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className={styles.feedback__question}>
          <span className={styles.feedback__question__question}>
            Your experience rating
          </span>
          <Rating state={setWebsiteExperience} />
        </div>

        <div className={styles.feedback__question}>
          <span className={styles.feedback__question__question}>
            What device are you using to view the website?
          </span>
          <form>
            {["Computer", "Tablet", "Mobile Phone"].map((device, index) => {
              return (
                <Fragment key={index}>
                  <input
                    type="radio"
                    onChange={(e) => setDevice(e.target.value)}
                    name="device"
                    value={device}
                  />
                  <label>{device}</label>
                  <br />
                </Fragment>
              );
            })}
          </form>
        </div>

        <div className={styles.feedback__question}>
          <span className={styles.feedback__question__question}>
            Rate the speed response of the website?
          </span>
          <Rating state={setWebsiteResponse} />
        </div>

        <div className={styles.feedback__question}>
          <span className={styles.feedback__question__question}>
            Rate the outcome of the search?
          </span>
          <Rating state={setSearchOutcome} />
        </div>

        <div className={styles.feedback__question}>
          <span className={styles.feedback__question__question}>
            Additional Feedback
          </span>
          <form className={styles.no__padding}>
            <textarea placeholder="If you have additional feedback, please type it in here"></textarea>
          </form>
        </div>

        <div className={styles.cta}>
          <button onClick={submitFeedback}>Submit Feedback</button>
        </div>
      </div>
    </div>
  );
}

const Rating = ({ state }) => {
  const [selectedRating, setSelectedRating] = useState(null);
  const [hoveredRating, setHoveredRating] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRating(index + 1);
  };

  const handleClick = (index) => {
    setSelectedRating(index + 1);
  };

  useEffect(() => {
    state(selectedRating);
  }, [selectedRating]);

  return (
    <div className={styles.rating}>
      {Array(5)
        .fill("")
        .map((_, index) => {
          return (
            <Fragment key={index}>
              <AiFillStar
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => setHoveredRating(null)}
                onClick={() => handleClick(index)}
                style={{ cursor: "pointer" }}
                color={
                  hoveredRating >= index + 1 || selectedRating >= index + 1
                    ? "yellow"
                    : "#EFF0F6"
                }
                size={30}
              />
            </Fragment>
          );
        })}
    </div>
  );
};
