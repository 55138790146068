import React, { useState, useEffect } from "react";

import { baseUrl } from "../../config";

import SearchIconWhite from "../../assets/search_icon_white.svg";

import styles from "./styles.module.scss";

export default function Graph() {
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const search = () => {
    window.location.replace(`/search?q=${searchTerm}&type=keywords`);
  };

  const checkKeyPress = (e) => {
    if (e.which === 13)
      return window.location.replace(`/search?q=${searchTerm}&type=keywords`);
  };

  useEffect(() => {
    (async () => {
      try {
        const request = await fetch(`${baseUrl}/gazettes-graph`);
        const response = await request.json();

        const currentYearArray = response.data[0];
        const monthsCounts = Object.keys(currentYearArray.months).length;
        console.log(monthsCounts);

        for (let i = 0; i < 12 - monthsCounts; i++) {
          currentYearArray.months[`fill_${i}`] = -1;
        }

        setData(response);
      } catch (error) {
        console.warn(error);
      }
    })();
  }, []);

  return (
    <div className={styles.graph}>
      <AppGraph data={data} />
      <div className={styles.search_container}>
        <div>
          <h3>Search Publications</h3>
        </div>
        <div>
          <input
            type="text"
            placeholder="Search by title, keyword or year"
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => checkKeyPress(e)}
          />
          <button onClick={search}>
            <img src={SearchIconWhite} alt="Search Icon" />
          </button>
        </div>
        <div>
          <h3>ADVANCED SEARCH</h3>
        </div>
      </div>
    </div>
  );
}

export const AppGraph = ({ data }) => {
  return (
    <>
      {!data && <div className={styles.graph_container}></div>}

      {data && (
        <div className={styles.graph_container}>
          {data.data.map((item, index) => {
            return (
              <div key={index} className={styles.graph_year}>
                <div className={styles.graph_months}>
                  {Object.keys(item.months).map((month, index) => {
                    return (
                      <React.Fragment key={month}>
                        <div
                          className={styles.month}
                          style={{
                            opacity:
                              item.months[month] > 0 &&
                              ((item.months[month] / 10) * 100) / 100,
                            backgroundColor:
                              // item.months[month] <= 0 && "#f5f5f5",
                              item.months[month] === 0
                                ? "#f5f5f5"
                                : item.months[month] < 0
                                ? "#ffff"
                                : null,
                          }}
                          onClick={() =>
                            window.location.replace(
                              `/search?q=${index + 1}/${
                                item.year
                              }&type=month&month=${index + 1}&year=${item.year}`
                            )
                          }
                        ></div>
                        <div
                          className={styles.month_tooltip}
                          style={{
                            opacity: 1,
                            display: item.months[month] < 0 && "none",
                          }}
                          onClick={() =>
                            window.location.replace(
                              `/search?q=${index + 1}/${
                                item.year
                              }&type=month&month=${index + 1}&year=${item.year}`
                            )
                          }
                        >
                          <span>
                            {`${month.toUpperCase()} ${item.year}`} <br />
                            {item.months[month]} GAZETTES
                          </span>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <span>{item.year}</span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
