import { useState, createContext, useContext } from "react";

export const FeedbackContext = createContext();

export const FeedbackModal = () => {
  const [openFeedback, setOpenFeedback] = useContext(FeedbackContext);
  return { openFeedback, setOpenFeedback };
};

export const FeedbackProvider = ({ children }) => {
  const [openFeedback, setOpenFeedback] = useState(false);

  return (
    <FeedbackContext.Provider value={[openFeedback, setOpenFeedback]}>
      {children}
    </FeedbackContext.Provider>
  );
};
