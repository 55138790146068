import Navbar from "./navbar";
import Landing from "./landing";
import Graph from "./graph";
import Footer from "./footer";
import Topnav from "./topnav";
import Filters from "./filters";
import Results from "./results";
import SearchComponent from "./search";

const components = {
  Navbar,
  Landing,
  Graph,
  Footer,
  Topnav,
  Filters,
  Results,
  SearchComponent,
};
export default components;
