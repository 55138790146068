import styles from "./styles.module.scss";
import { useState, useEffect, useCallback, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { loadPDF } from "../../helpers/embedPDF";
import { AiOutlineArrowUp } from "react-icons/ai";
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";

import download_icon from "../../assets/download.svg";
import bookmark_icon from "../../assets/bookmark.svg";
import share_icon from "../../assets/share_icon_white.svg";

import { baseUrl } from "../../config";
import { User } from "../../contexts/user";
import dayjs from "dayjs";
import { toast } from "react-toastify";

export default function Gazette({ data, pdfIsMaximized, setpdfIsMaximized }) {
  const { setUserModal, setShareModal } = User();
  const [bookmarked, setBookmarked] = useState(false);
  const [tab, setTab] = useState(1);

  const pubRef = useRef();
  const gazetteRef = useRef();
  const scrollBtn = useRef();

  const setBookmark = useCallback(
    async (id) => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        sessionStorage.setItem("documentId", id);
        return setUserModal(true);
      }

      const toastID = toast.loading("Bookmarking gazette...");

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gazette_id: id,
        }),
      };

      try {
        const request = await fetch(`${baseUrl}/bookmarks`, config);
        const response = await request.json();

        if (response.success) {
          toast.update(toastID, {
            render: "Gazette Bookmarked",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          return setBookmarked(true);
        }

        return toast.update(toastID, {
          render: response.message || "An error occurred. Try again later",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      } catch (error) {
        return toast.update(toastID, {
          render: "An error occurred. Try again later",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      }
    },
    [setUserModal]
  );

  const handleScroll = useCallback((e) => {
    const scrollTop = gazetteRef.current.getBoundingClientRect().top;

    if (pubRef.current.getBoundingClientRect().top >= 170) {
      gazetteRef.current.classList.remove(styles.scroll);
      scrollBtn.current.style.display = "none";
      return;
    }

    if (scrollTop <= 20) {
      gazetteRef.current.classList.add(styles.scroll);
      scrollBtn.current.style.display = "grid";
      return;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const setShare = (slug) => {
    sessionStorage.setItem("slugLink", slug);
    sessionStorage.setItem(
      "shareData",
      JSON.stringify({
        title: data.title,
        volume: data.volume,
        assented_by: data.published_date,
      })
    );
    setShareModal(true);
  };

  const handleView = useCallback(async () => {
    const token = sessionStorage.getItem("token");

    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        gazette_id: data.id,
      }),
    };

    try {
      await fetch(`${baseUrl}/views`, config);
    } catch (error) {
      console.warn(error);
    }
  }, [data]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    handleView();

    if (params.action === "bookmark") {
      const id = sessionStorage.getItem("documentId");
      return setBookmark(id);
    }
  }, [data, setBookmark, handleView]);

  const handleDownload = async (id) => {
    const token = sessionStorage.getItem("token");

    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        gazette_id: id,
      }),
    };

    try {
      await fetch(`${baseUrl}/downloads`, config);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <div className={styles.gazette}>
      <div
        className={styles.top}
        ref={scrollBtn}
        onClick={() => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }}
      >
        <AiOutlineArrowUp size={20} color="#129017" />
      </div>

      {data && (
        <>
          <div className={styles.gazette_info} ref={gazetteRef}>
            <h4>
              {data.title}
              <span className={styles.gazette_date_hidden}>
                | {dayjs(data.published_date).format("DD MMMM, YYYY")}
              </span>
            </h4>
            {/* <span className={styles.gazette_category}>
              {data.category && data.category.title}
            </span> */}
            <span className={styles.gazette_date}>
              <b>Date of Assent: </b>
              {dayjs(data.ascent_date).format("DD MMMM, YYYY")}
            </span>

            <span className={styles.gazette_date}>
              <b>Date of Publication: </b>
              {dayjs(data.published_date).format("DD MMMM, YYYY")}
            </span>

            <span className={styles.gazette_date}>
              <b>Volume no.</b> {data.volume}
            </span>

            <span className={styles.gazette_senate}>
              <b>Assented by: </b>
              President {data.president && data.president.name}
            </span>

            {/* <span>Tags: {}</span> */}
            <div className={styles.gazette_tags}>
              {/* <div className={styles.tags}>
                <span>
                  <b>Tags:</b>{" "}
                  <b></b>{" "}
                </span>
                {data.tags?.map((tag) => {
                  return (
                    // <span key={tag} className={styles.tag_box}>
                    //   {tag.name}
                    // </span>
                    <span></span>
                  );
                })}
              </div> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <AudioPlayer data={data} />
              </div>

              <div className={styles.action_buttons}>
                <a
                  onClick={() => handleDownload(data.id)}
                  href={data.file_url}
                  rel="noreferrer"
                  download
                  target="_blank"
                >
                  <button data-tip data-for="downloadTip">
                    <img src={download_icon} alt="download icon" />

                    <ReactTooltip
                      id="downloadTip"
                      place="bottom"
                      effect="solid"
                    >
                      Download
                    </ReactTooltip>
                  </button>
                </a>

                <button
                  data-tip
                  data-for="bookmarkTip"
                  onClick={() => setBookmark(data.id)}
                  style={{ opacity: bookmarked && 0.5 }}
                >
                  <img src={bookmark_icon} alt="bookmark icon" />
                  <ReactTooltip id="bookmarkTip" place="bottom" effect="solid">
                    Bookmark
                  </ReactTooltip>
                </button>

                <button data-tip data-for="shareTip">
                  <img
                    src={share_icon}
                    alt="bookmark icon"
                    onClick={() => setShare(data.slug)}
                  />

                  <ReactTooltip id="shareTip" place="bottom" effect="solid">
                    Share
                  </ReactTooltip>
                </button>
              </div>
            </div>
          </div>

          <div className={styles.gazette_summary} ref={pubRef}>
            <h4>Publication Summary</h4>
            <p>{data.abstract}</p>
          </div>

          <div
            className={`${styles.gazette_document} ${
              pdfIsMaximized && styles.maximized
            }`}
          >
            <div className={styles.gazette_document_tabs}>
              <div
                style={{
                  cursor: "pointer",
                  background: tab !== 1 && "#fafafa",
                }}
                onClick={() => setTab(1)}
              >
                <p
                  style={{
                    fontWeight: tab === 1 && "700",
                    color: tab === 1 && "green",
                  }}
                >
                  Text Document
                </p>
              </div>

              <div
                style={{
                  cursor: "pointer",
                  borderTop: tab === 2 && "1px solid #c4c4c4",
                  borderRight: tab === 2 && "1px solid #c4c4c4",
                  borderBottom: tab === 2 && "none",
                  background: tab === 2 && "#fff",
                }}
                onClick={() => setTab(2)}
              >
                <p
                  style={{
                    fontWeight: tab === 2 && "700",
                    color: tab === 2 && "green",
                  }}
                >
                  Original PDF
                </p>
              </div>

              <div
                className={styles.maximizeBtn}
                style={{ cursor: "pointer" }}
                onClick={() => setpdfIsMaximized(!pdfIsMaximized)}
              >
                {pdfIsMaximized ? <FiMinimize2 /> : <FiMaximize2 />}
              </div>
            </div>

            {tab === 1 && (
              <div style={{ marginBottom: "10px", padding: "0 20px" }}>
                <p style={{ fontSize: "12px", textAlign: "center" }}>
                  This is the automatically generated text version of the
                  Gazette, to view the original file, click "Original PDF" above
                </p>
              </div>
            )}

            <div
              className={styles.gazette_file}
              // style={{ border: !numPages && "none" }}
              id="pdf-div"
              onDocumentLoad={loadPDF({
                url: tab === 1 ? data.file_url : data.document_url,
                id: "pdf-div",
                name: data.title,
              })}
            ></div>
          </div>

          <div className={styles.gazette_mobile_buttons}>
            <a
              href={data.document_url}
              rel="noreferrer"
              download
              target="_blank"
              style={{ display: "flex", alignItems: "center", color: "#fff" }}
            >
              <button>View Original PDF</button>
            </a>
            {/* <AudioPlayer data={data} /> */}
          </div>

          <div className={styles.gazette_mobile_buttons}>
            <a
              href={data.file_url}
              rel="noreferrer"
              download
              target="_blank"
              style={{ display: "flex", alignItems: "center", color: "#fff" }}
            >
              <button>View Text Document</button>
            </a>
            {/* <AudioPlayer data={data} /> */}
          </div>
        </>
      )}
    </div>
  );
}

const AudioPlayer = ({ data }) => {
  const [playIndex, setPlayIndex] = useState(0);
  const audioRef = useRef();

  const handleAudioEnd = () => {
    setPlayIndex((playIndex) => playIndex + 1);
  };

  useEffect(() => {
    if (playIndex === 0) return;

    if (playIndex > data.polly_url.length - 1) {
      audioRef.current.src = data.polly_url[0];
      return;
    }

    audioRef.current.src = data.polly_url[playIndex];
    audioRef.current.play();
  }, [playIndex, data]);

  return (
    <>
      <span style={{ fontSize: "12px", marginRight: "10px" }}>
        Listen to this document
      </span>
      <audio ref={audioRef} controls onEnded={handleAudioEnd}>
        <source src={data.polly_url[playIndex]} type="audio/mpeg" />
      </audio>
    </>
  );
};
