import { useState } from "react";
// import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { baseUrl } from "../../config";
// import { User } from "../../contexts/user";

import password_open from "../../assets/password_open.svg";
import password_close from "../../assets/password_close.svg";

import styles from "../../pages/signin/styles.module.scss";

export const ResetPassword = ({ setState, action, page, pwdToken, email }) => {
  // const { setUser } = User();

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  const [passwordState, setPasswordState] = useState("password");
  const [verifyPasswordState, setVerifyPasswordState] = useState("password");

  const reset = async () => {
    setProcessing(true);

    if (password !== passwordConfirmation) {
      setProcessing(false);
      return toast.error("Passwords do not match");
    }

    const config = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: pwdToken,
        email,
        password,
        password_confirmation: passwordConfirmation,
      }),
    };

    try {
      const request = await fetch(`${baseUrl}/reset-password`, config);
      const response = await request.json();

      setProcessing(false);
      if (!response.success) {
        return setError(response.message);
      }

      if (action) {
        window.location.replace(`${page}?action=${action}`);
        return;
      }

      toast.success("Password reset successfully. Please login");
      window.location.replace(`${page}?action=${action}`);
      // window.location.replace(`/`);
    } catch (error) {
      console.warn(error);
      setProcessing(false);
    }
  };

  return (
    <div className={styles.login}>
      <h3>RESET PASSWORD</h3>

      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <span className={styles.form_error}>{error}</span>

          <label htmlFor="">Create new Password</label>
          <div className={styles.form_login_box}>
            <input
              type={passwordState}
              placeholder="**************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.form_login_box_input}
            />
            {passwordState === "password" && (
              <img
                src={password_close}
                alt="show password"
                onClick={() => setPasswordState("text")}
              />
            )}
            {passwordState === "text" && (
              <img
                src={password_open}
                alt="hide password"
                onClick={() => setPasswordState("password")}
              />
            )}
          </div>

          <label htmlFor="">Verify new Password</label>
          <div className={styles.form_login_box}>
            <input
              type={verifyPasswordState}
              placeholder="**************"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              className={styles.form_login_box_input}
            />
            {verifyPasswordState === "password" && (
              <img
                src={password_close}
                alt="show password"
                onClick={() => setVerifyPasswordState("text")}
              />
            )}
            {verifyPasswordState === "text" && (
              <img
                src={password_open}
                alt="hide password"
                onClick={() => setVerifyPasswordState("password")}
              />
            )}
          </div>

          {!processing ? (
            <button
              className={styles.form_submit}
              type="submit"
              onClick={reset}
            >
              Reset
            </button>
          ) : (
            <span className={styles.form_processing}>
              Creating a new Password...
            </span>
          )}

          <span>
            Don't have an account?{" "}
            <button onClick={() => setState("register")}>Register</button>
          </span>
        </form>
      </div>
    </div>
  );
};
