import ViewSDKClient from "../utils/config/ViewSDKClient";

export const loadPDF = ({ url, id, name }) => {
  const viewSDKClient = new ViewSDKClient({ fileName: name });

  viewSDKClient.ready().then(() => {
    viewSDKClient.previewFile(
      id,
      {
        defaultViewMode: "FIT_WIDTH",
        showAnnotationTools: false,
        showLeftHandPanel: true,
        showPageControls: true,
        showDownloadPDF: true,
        showPrintPDF: false,
        showDisabledSaveButton: true,
        embedMode: "IN_LINE",
      },
      url
    );
  });
};
