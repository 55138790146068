import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";

import { BsFillBookmarkCheckFill } from "react-icons/bs";

import Loader from "../loader";

import { baseUrl } from "../../config";
import { User } from "../../contexts/user";

import download_icon from "../../assets/download.svg";
import bookmark_icon from "../../assets/bookmark.svg";
import share_icon from "../../assets/share_icon_white.svg";

import styles from "./styles.module.scss";

export default function Results({ props }) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const { setUserModal, setShareModal, user } = User();

  const { filteredResults, loading } = props;
  // const results = filteredResults;
  const [results, setResults] = useState(filteredResults);
  const [pagination, setPagination] = useState({
    start: 0,
    end: 20,
    hasMore: filteredResults.length > 20,
  });

  const [bookmarks, setBookmarks] = useState([]);
  const [keywords] = useState([]);

  const setBookmark = useCallback(
    async (id) => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        sessionStorage.setItem("documentId", id);
        return setUserModal(true);
      }

      const tid = toast.loading("Bookmarking Gazette");

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gazette_id: id,
        }),
      };

      try {
        const request = await fetch(`${baseUrl}/bookmarks`, config);
        const response = await request.json();

        if (response.success) {
          toast.update(tid, {
            render: "Gazette Bookmarked",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          return setBookmarks([...bookmarks, id.toString()]);
        } else {
          return toast.update(tid, {
            render: response.message || "Could not bookmark gazette",
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [bookmarks, setUserModal]
  );

  const setShare = (data) => {
    sessionStorage.setItem("slugLink", data.slug);
    sessionStorage.setItem(
      "shareData",
      JSON.stringify({
        title: data.title,
        volume: data.volume,
        assented_by: data.published_date,
      })
    );
    setShareModal(true);
  };
  useEffect(() => {
    setPagination({
      start: 0,
      end: 20,
      hasMore: filteredResults.length > 20,
    });
  }, [filteredResults]);

  useEffect(() => {
    const paginatedArray = filteredResults.slice(
      pagination.start,
      pagination.end
    );
    setResults(paginatedArray);
  }, [pagination, filteredResults]);

  useEffect(() => {
    if (user) {
      const array = [];
      user.bookmarks.map((item) => {
        return array.push(item.gazette_id.toString());
      });
      setBookmarks([...array]);
    }
  }, [user]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.action === "bookmark") {
      const id = sessionStorage.getItem("documentId");
      return setBookmark(id);
    }
  }, [setBookmark]);

  const handleDownload = async (id) => {
    const token = sessionStorage.getItem("token");

    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        gazette_id: id,
      }),
    };

    try {
      await fetch(`${baseUrl}/downloads`, config);
    } catch (error) {
      console.warn(error);
    }
  };

  const fetchNextResults = () => {
    setPagination({
      start: pagination.start + 20,
      end: pagination.end + 20,
      hasMore: filteredResults.length > pagination.end + 20,
    });
  };

  const fetchPreviousResults = () => {
    setPagination({
      start: pagination.start - 20,
      end: pagination.end - 20,
      hasMore: true,
    });
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className={styles.results}>
      {!loading ? (
        <>
          <span className={styles.header}>
            <b>Showing search results</b>
            {props.query && (
              <b>
                : Gazettes dated "
                {params.month
                  ? `${months[Number(params.month) - 1]} ${params.year}`
                  : ""}
                "{/* <b>: {props.query} */}
              </b>
            )}
            <br /> <br />
            <i>
              {" "}
              {results.length} of {props.documentCount} Document(s)
            </i>
          </span>

          {results.length > 0 &&
            results.map((result) => {
              return (
                <div key={result.id} className={styles.result}>
                  <Link to={`/document/${result.slug}`}>
                    <div className={styles.result_header}>
                      <h4>
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[...keywords, ...[props.query]]}
                          autoEscape={true}
                          textToHighlight={result.title || ""}
                        />
                      </h4>
                      <span>
                        <b>Date of Assent:</b>{" "}
                        {dayjs(result.ascent_date).format("DD MMMM, YYYY")}
                      </span>
                    </div>
                    {/* <span className={styles.result_category}>
                      {result.category && (
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[...keywords, ...[props.query]]}
                          autoEscape={true}
                          textToHighlight={result.category || ""}
                        />
                      )}
                    </span> */}
                    <p>
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={[...keywords, ...[props.query]]}
                        autoEscape={true}
                        textToHighlight={result.abstract ? result.abstract : ""}
                      />
                    </p>
                  </Link>
                  <div className={styles.action_buttons}>
                    <button
                      data-tip
                      data-for="downloadTip"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <a
                        onClick={() => handleDownload(result.id)}
                        href={result.url}
                        rel="noreferrer"
                        download
                        target="_blank"
                        // style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={download_icon}
                          alt="download icon"
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />
                      </a>
                      <ReactTooltip id="downloadTip" place="top" effect="solid">
                        Download
                      </ReactTooltip>
                    </button>

                    {!bookmarks.includes(result.id.toString()) ? (
                      <button
                        data-tip
                        data-for="bookmarkTip"
                        onClick={() => setBookmark(result.id)}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={bookmark_icon}
                          alt="bookmark icon"
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <ReactTooltip
                          id="bookmarkTip"
                          place="top"
                          effect="solid"
                        >
                          Bookmark
                        </ReactTooltip>
                      </button>
                    ) : (
                      <button
                        data-tip
                        data-for="bookmarkedTip"
                        style={{ opacity: 0.5 }}
                      >
                        <BsFillBookmarkCheckFill />
                        <ReactTooltip
                          id="bookmarkedTip"
                          place="top"
                          effect="solid"
                        >
                          Already Bookmarked
                        </ReactTooltip>
                      </button>
                    )}
                    <button
                      data-tip
                      data-for="shareTip"
                      onClick={() => setShare(result)}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={share_icon}
                        alt="share icon"
                        style={{
                          width: "15px",
                          height: "15px",
                        }}
                      />
                      <ReactTooltip id="shareTip" place="top" effect="solid">
                        Share
                      </ReactTooltip>
                    </button>
                  </div>
                </div>
              );
            })}

          {results.length === 0 && (
            <div className={styles.no_result}>
              <span>
                There were no results found for your search. Change your search
                term or filters and try again
              </span>
            </div>
          )}

          {results.length !== 0 && (
            <div className={styles.pagination}>
              <button
                style={{
                  cursor: pagination.start === 0 ? "not-allowed" : null,
                }}
                onClick={() => {
                  if (pagination.start === 0) return;
                  fetchPreviousResults();
                }}
              >
                Prev
              </button>
              <button
                style={{
                  opacity: !pagination.hasMore ? 0.5 : null,
                  cursor: !pagination.hasMore ? "not-allowed" : null,
                }}
                onClick={() => {
                  if (!pagination.hasMore) return;
                  fetchNextResults();
                }}
              >
                Next
              </button>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
