import { useEffect, useState } from "react";
import ReactGA from "react-ga";

import components from "../../components";

import { searchUrl } from "../../config";

import styles from "./styles.module.scss";

export default function Search() {
  const [query, setQuery] = useState(null);
  const [searchType, setSearchType] = useState("keywords");
  const [loading, setLoading] = useState(true);

  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const [clearFilters, setClearFilters] = useState(true);
  const [documentCount, setDocumentCount] = useState(0);
  // const [meta, setMeta] = useState({});

  const [categories, setCategories] = useState([]);
  const [years, setYears] = useState([]);
  const [tags, setTags] = useState([]);
  const [keywords, setKeywords] = useState("");

  const [showFilters, setShowFilters] = useState(true);

  const filters = {
    years,
    categories,
    setYears,
    setCategories,
    tags,
    setTags,
  };

  const filterResults = async () => {
    setLoading(true);
    if (window.innerWidth < 1200) setShowFilters(false);
    try {
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "T#uUJ7+%U:5%PY}4",
        },
        body: JSON.stringify({
          category: categories,
          tags,
          year: years,
        }),
      };

      const request = await fetch(`${searchUrl}`, config);
      const response = await request.json();

      response.data ? setResults(response.data) : setResults([]);
      response.data
        ? setFilteredResults(response.data)
        : setFilteredResults([]);
      setLoading(false);
    } catch (error) {
      console.warn(error);
      setLoading(false);
    }
  };

  const props = {
    query,
    loading,
    results,
    setResults,
    filteredResults,
    filters,
    filterResults,
    clearFilters,
    setClearFilters,
    keywords,
    setKeywords,
    documentCount,
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.q ? setQuery(params.q) : setQuery("");
    params.type && setSearchType(params.type);

    ReactGA.initialize("UA-215525316-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    document.title = "Search - Nigerian Gazettes";
  }, []);

  useEffect(() => {
    (async () => {
      if (query === null) return;

      try {
        const body = {};

        body[searchType] = [query];

        if (!query) {
          body[searchType] = [];
        }

        if (searchType === "month") {
          body[searchType] = query;
        }
        const config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "T#uUJ7+%U:5%PY}4",
          },
          body: JSON.stringify(body),
        };

        const request = await fetch(`${searchUrl}`, config);
        const response = await request.json();

        // setMeta(response.meta);
        setDocumentCount(response.data.length);

        response.data ? setResults(response.data) : setResults([]);
        response.data
          ? setFilteredResults(response.data)
          : setFilteredResults([]);
        setLoading(false);
      } catch (error) {
        console.warn(error);
        setLoading(false);
      }
    })();
  }, [query, searchType]);

  useEffect(() => {
    const obj = {
      years,
      categories,
    };

    sessionStorage.setItem("filters", JSON.stringify(obj));
  }, [years, categories]);

  return (
    <div className={styles.search}>
      <header>
        <components.Navbar />
        <section style={{ background: "#fff" }}>
          <components.SearchComponent />
        </section>
      </header>

      <main>
        <components.Results props={props} />
        <components.Filters
          props={props}
          results={results}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          setFilteredResults={setFilteredResults}
        />
      </main>
    </div>
  );
}
