import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { User } from "../../contexts/user";
import { Request } from "../../utils/requests/auth";

// Icons
import password_open from "../../assets/password_open.svg";
import password_close from "../../assets/password_close.svg";

import styles from "../../pages/signin/styles.module.scss";

export const LoginForm = ({ setState, action, page }) => {
  const { setUser } = User();
  const navigate = useNavigate();

  const [{ email, password }, setCredentials] = useState({
    email: "",
    password: "",
  });

  const [processing, setProcessing] = useState(false);
  const [passwordState, setPasswordState] = useState("password");

  const handleLogin = async () => {
    if (processing) return;

    const id = toast.loading("Signing you in...");
    setProcessing(true);

    try {
      const request = new Request({
        path: "login",
        params: { email, password },
      });
      const response = await request.login();

      setProcessing(false);

      if (response.redirect) {
        return toast.update(id, {
          render: "Redirecting to Admin page",
          type: "info",
          isLoading: false,
          autoClose: 2000,
        });
      }

      if (!response.status) {
        return toast.update(id, {
          render: response.message || "An error occurred. Try again later",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      }

      toast.update(id, {
        render: "Logged In",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });

      setUser(response.token.original.user);

      if (action) {
        navigate(`${page}?action=${action}`);
        return;
      }
      navigate("/");
    } catch (error) {
      console.log(error);
      setProcessing(false);
      return toast.update(id, {
        render: "An error occurred. Try again later",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  const handleEmailChange = (e) => {
    setCredentials({
      email: e.target.value,
      password,
    });
  };

  const handlePasswordChange = (e) => {
    setCredentials({
      password: e.target.value,
      email,
    });
  };

  return (
    <div className={styles.login}>
      <h3>LOGIN</h3>

      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <label htmlFor="">Email</label>
          <input
            type="text"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => handleEmailChange(e)}
          />

          <br />

          <label htmlFor="">Password</label>
          <div className={styles.form_login_box}>
            <input
              type={passwordState}
              placeholder="**************"
              value={password}
              onChange={(e) => handlePasswordChange(e)}
              className={styles.form_login_box_input}
            />

            {passwordState === "password" && (
              <img
                src={password_close}
                alt="show password"
                onClick={() => setPasswordState("text")}
              />
            )}

            {passwordState === "text" && (
              <img
                src={password_open}
                alt="hide password"
                onClick={() => setPasswordState("password")}
              />
            )}
          </div>

          <button
            className={styles.form_submit}
            type="submit"
            onClick={handleLogin}
            style={{ opacity: processing ? 0.5 : null }}
          >
            Login
          </button>

          <span>
            Don't have an account?{" "}
            <button onClick={() => setState("register")}>Register</button>
          </span>

          <p
            onClick={() => setState("reset")}
            style={{
              marginTop: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Forgot Password?
          </p>
        </form>
      </div>
    </div>
  );
};
