import React, { Fragment, useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";

import Navbar from "../../components/navbar";

import ReactGA from "react-ga";
import components from "../../components";

import { faqData } from "../../static/faq";

import styles from "./styles.module.scss";

export default function FAQ() {
  useEffect(() => {
    document.title = "FAQ - Nigerian Gazettes";
    ReactGA.initialize("UA-215525316-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className={styles.faq}>
      <header>
        <Navbar />
      </header>

      <main className={styles.main}>
        <div className={styles.header}>
          <h3>FREQUENTLY ASKED QUESTIONS</h3>
        </div>

        <div className={styles.faq_desc}>
          <span>
            Welcome to our Frequently Asked Questions (FAQs) page. If you can't
            find the answer to your question below, send us an email on
            faq@gazettes.ng.
          </span>
        </div>

        <div className={styles.faq_container}>
          {faqData.map((data, index) => {
            return (
              <Fragment key={index}>
                <DetailsContainer header={data.header} text={data.text} />
              </Fragment>
            );
          })}
        </div>
      </main>

      <components.Footer />
    </div>
  );
}

const DetailsContainer = ({ header, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.detailsDiv}>
      <div className={styles.detailsDivHeader} onClick={() => setOpen(!open)}>
        <span>{header}</span>
        <BiChevronDown />
      </div>

      <div className={`${styles.detailsDivBody} ${open && styles.bodyOpen}`}>
        <span>{text}</span>
      </div>
    </div>
  );
};

{
  /* <div>
            <details>
              <summary>What is a Gazette? </summary>
              <span>
                A Gazette is an official publication for the purpose of
                notifying the actions and decisions of the government.
              </span>
            </details>

            <details>
              <summary>What is the purpose of the Gazette.gov.ng?</summary>
              <span>
                The Gazette.gov.ng is an official Government publication.
                Government uses it to publish acts that have been published by
                the National Assembly.
              </span>
            </details>

            <details>
              <summary>What are the types of Gazettes?</summary>
              <span>
                Gazettes are of two types Acts and Subsidiary regulations.
              </span>
            </details>

            <details>
              <summary>
                Are the Acts and Regulations the official versions?
              </summary>
              <span>
                Yes, all Gazettes on the Website are "official", this means that
                they can be used for evidentiary purposes.
              </span>
            </details>

            <details>
              <summary>How often is the website updated?</summary>
              <span>
                The Gazettes.gov.ng website is generally updated every Month.
              </span>
            </details>

            <details>
              <summary>How can I share a document?</summary>
              <span>
                On the website, for a document you choose there is an option to
                share via E-mail. Input details, copy the link and share.
              </span>
            </details>

            <details>
              <summary>How can I give feedback on the Website?</summary>
              <span>
                You can give your feedbacks on the website by clicking on
                “FEEDBACK” located at the bottom of the website.A
              </span>
            </details>

            <details>
              <summary>What can I find on the “Home” page?</summary>
              <span>
                On the home page you can find the Navigation bar, the most
                recent publications on the right side, the section showing
                publications by year to the left, the search bar and the footer
                bar.
              </span>
            </details>

            <details>
              <summary>What can I find on the “About” page?</summary>
              <span>
                On this page you can find out about .gov.ng and about our
                partners.
              </span>
            </details>

            <details>
              <summary>What can I find on the “help / FAQ” page?</summary>
              <span>
                On the help page you can find frequently asked questions that
                can be of help to you.
              </span>
            </details>

            <details>
              <summary>How can I “login”?</summary>
              <span>
                You can Login by clicking on Login then typing your e-mail and
                password in the required places.
              </span>
            </details>
          </div> */
}

{
  /* <div>
            <details>
              <summary>
                In what format are dates displayed on the Gazettes.gov.ng
                website?
              </summary>
              <span>Dates are displayed in the DD-MM-YYYY format.</span>
            </details>

            <details>
              <summary>What does amendment mean in the Constitution?</summary>
              <span>
                Amendment, in government and law, an addition or alteration made
                to a constitution, statute, or legislative bill or resolution.
                Amendments can be made to existing constitutions and statutes
                and are also commonly made to bills during their passage through
                a legislature. Since amendments to a national constitution can
                fundamentally change a country’s political system or governing
                institutions, such amendments are usually submitted to an
                exactly prescribed procedure.
              </span>
            </details>

            <details>
              <summary>What does it mean to repeal a law?</summary>
              <span>
                Repeal is the rescission of an existing law by subsequent
                legislation or constitutional amendment.
              </span>
            </details>

            <details>
              <summary>
                What are years of publications does Gazette.gov.ng have?
              </summary>
              <span>
                The Gazette.gov.ng website has publications from 1999 – till
                date.
              </span>
            </details>

            <details>
              <summary>How do I search?</summary>
              <span>
                A search can be performed by using the “search bar” at the
                bottom right side of the website. You can search using title,
                year or keywords.
              </span>
            </details>

            <details>
              <summary>
                How do I search for a publication in a particular year?
              </summary>
              <span>
                On the navigation bar, click on “publications” then select the
                year dropdown on the left, you can select the particular year
                you are searching for.
              </span>
            </details>

            <details>
              <summary>How can I recover password?</summary>
              <span>
                If you forget your password, you can simply click on “Forgot
                password?”, this will take you to another page where you will
                put in your e-mail click on “reset”, after this you will be sent
                an email to confirm your new password.
              </span>
            </details>

            <details>
              <summary>How can I “Register”?</summary>
              <span>
                To register a new account, click on “register” on the navigation
                bar. After this you will be taken to another page where you will
                fill out your details such as e-mail and password, afterwards
                you click on “register” on that page.
              </span>
            </details>

            <details>
              <summary>What can I find on the footer of the website?</summary>
              <span>
                On the footer of the Gazettes.gov.ng website you will find the
                icon for “feedback”, a link to details about our partners, NILDS
                and KAS.
              </span>
            </details>

            <details>
              <summary>What can I find on the advanced search page?</summary>
              <span>
                On this page you will find search results on the left side of
                the page and on the right side of the page you will see an
                option for filter. You can filter by Categories contains “Acts”
                and “Subsidiary regulations”, “year of assent” which displays
                different years the laws where signed by the president, are
                “Tags” and “volume no” of the publication you are looking for.
              </span>
            </details>
          </div> */
}
