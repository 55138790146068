import { useCallback, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { toast } from "react-toastify";

import { baseUrl } from "../../config";

import { User } from "../../contexts/user";
// import { SearchModal } from "../../contexts/search_modal";

import Logo from "../../assets/Logo.svg";
// import SearchIcon from "../../assets/search_icon_white.svg";

import styles from "./styles.module.scss";

export default function Navbar() {
  const { user, setUser } = User();
  // const { setOpenSearch } = SearchModal();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  // const current_route = useLocation().pathname;
  // const search_route = current_route === "/search";
  // const search_route = undefined;

  const pathname = window.location.pathname;

  const openMobileNavigation = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const menu_options = [
    {
      id: "home",
      render: "Home",
      path: "/",
      user: true,
    },
    {
      id: "about",
      render: "About",
      path: "/about",
      user: true,
    },
    {
      id: "publications",
      render: "Publications",
      path: "/publications",
      user: true,
    },
    {
      id: "help",
      render: "Help / FAQ",
      path: "/faq",
      user: true,
    },
    {
      id: "signin",
      render: "Register / Login",
      path: "/signin",
      user: false,
    },
    {
      id: "account",
      render: "My Account",
      path: "/profile",
      auth: true,
      user: true,
    },
  ];

  const logout = async () => {
    const token = sessionStorage.getItem("token");

    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    };

    try {
      const request = await fetch(`${baseUrl}/logout`, config);
      const response = await request.json();

      if (response.success) {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        setUser(null);

        toast("Logged out successfully");
      }
    } catch (error) {
      console.warn(error);
    }
  };

  // const open_search = () => {
  //   setOpenSearch(true);
  // };

  return (
    <div className={styles.navbar}>
      <MobileNavBar
        opened={mobileNavOpen}
        links={menu_options}
        setOpen={openMobileNavigation}
        user={user}
        handleLogout={logout}
      />
      <div className={styles.navbar_logo}>
        <Link to="/">
          <img src={Logo} alt="Nigerian Gazette Logo" />
        </Link>
      </div>

      <div className={styles.navbar_links}>
        <ul>
          {user
            ? menu_options
                .filter((item) => item.user)
                .map((item) => {
                  return (
                    <li key={item.id}>
                      <NavLink
                        to={`${item.path}`}
                        style={{
                          color: pathname === item.path && "#129017",
                          fontWeight: pathname === item.path && "700",
                        }}
                      >
                        {item.render.toUpperCase()}
                      </NavLink>
                    </li>
                  );
                })
            : menu_options.map((item) => {
                return (
                  <li
                    key={item.id}
                    style={{ display: !user && item.auth && "none" }}
                  >
                    <NavLink
                      to={`${item.path}`}
                      style={{
                        color: pathname === item.path && "#129017",
                        fontWeight: pathname === item.path && "700",
                      }}
                    >
                      {item.render.toUpperCase()}
                    </NavLink>
                  </li>
                );
              })}
        </ul>
      </div>

      {/* {user && (
        <div
          className={`${styles.navbar_search} ${styles.navbar_account}`}
          style={{ background: "none" }}
        >
          <Link to="/profile">
            <button>MY ACCOUNT</button>
          </Link>
        </div>
      )} */}

      <div className={styles.hamburger}>
        <HiOutlineMenuAlt3 onClick={openMobileNavigation} />
      </div>

      {/* {!search_route && (
        <div className={styles.navbar_search} onClick={open_search}>
          <img src={SearchIcon} alt="Search Icon" />
        </div>
      )} */}
    </div>
  );
}

const MobileNavBar = ({ opened, links, setOpen, user, handleLogout }) => {
  const handleClick = useCallback(
    (event) => {
      if (!opened) return;

      if (event.target.id !== "mobile_nav") {
        setOpen();
      }
    },
    [setOpen, opened]
  );

  useEffect(() => {
    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div
      className={`${styles.mobile_navbar} ${opened && styles.open}`}
      id="mobile_nav"
    >
      <div className={styles.top_container}>
        <div className={styles.logo_container}>
          <h1 style={{ color: "green", fontSize: "17px" }}>MENU</h1>
        </div>

        <div className={styles.hamburger}>
          <IoClose color="#000" onClick={setOpen} />
        </div>
      </div>

      <div className={styles.bottom_container}>
        <div className={styles.menuLinks_container}>
          <ul>
            {user
              ? links
                  .filter((item) => item.user)
                  .map((item, index) => (
                    <li key={index}>
                      <Link to={`${item.path.toLowerCase()}`}>
                        {item.render}
                      </Link>
                    </li>
                  ))
              : links.map((item, index) => (
                  <li key={index}>
                    <Link to={`${item.path.toLowerCase()}`}>{item.render}</Link>
                  </li>
                ))}
          </ul>
        </div>

        {user && (
          <div
            className={styles.navbar_search}
            style={{ background: "none", padding: 0 }}
          >
            <Link to="/profile">
              <button style={{ display: "block" }}>MY ACCOUNT</button>
            </Link>
          </div>
        )}

        <div className={styles.actions}>
          <div className={styles.auth}>
            {user && (
              <button style={{ color: "#fff" }} onClick={handleLogout}>
                LOGOUT
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
