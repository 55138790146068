import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app";
import { SearchProvider } from "./contexts/search_modal";
import { UserProvider } from "./contexts/user";
import { FeedbackProvider } from "./contexts/feedback_modal";

ReactDOM.render(
  <React.StrictMode>
    <SearchProvider>
      <FeedbackProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </FeedbackProvider>
    </SearchProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
